/**
 * Buttons
 *
 * @author Takuto Yanagida
 * @version 2023-09-21
 */

@use 'common';

%base-button-rect {
	@extend %base;

	color           : var(--nc-button-color);
	background-color: var(--nc-button-background-color);
	border          : var(--nc-button-border-base);
	border-color    : var(--nc-button-border-color);

	padding: var(--nc-button-padding);

	display    : inline-grid;
	place-items: center;

	cursor     : pointer;
	user-select: none;
}

@mixin button {
	@extend %base-button-rect;
}
