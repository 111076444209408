/**
 * Usable View
 *
 * @author Takuto Yanagida
 * @version 2023-01-17
 */

/*
--nc-table-background   : Canvas;
--nc-table-shadow-sticky: 0 0.5rem 0.5rem -0.5rem #00000080;
--nc-table-shadow-right : inset -2rem 0 1rem -1rem #0000001a;
--nc-table-shadow-left  : inset  2rem 0 1rem -1rem #0000001a;
*/

@mixin sticky-header-container {
	display : none;
	position: fixed;
	top     : 0;
	margin  : 0 !important;  // For canceling base margins
	overflow: hidden;

	background: var(--nc-table-background, Canvas);
	box-shadow: var(--nc-table-shadow-sticky, 0 0.5rem 0.5rem -0.5rem #00000080);

	&::after {
		content : '';
		display : flex;
		position: sticky;
		inset   : 0;

		pointer-events: none;

		height    : var(--nc-height);
		margin-top: calc(-1 * var(--nc-height));

		box-shadow:
			var(--nc-table-shadow-right, inset -2rem 0 1rem -1rem #0000001a),
			var(--nc-table-shadow-left,  inset  2rem 0 1rem -1rem #0000001a);
	}

	&:not([data-nc-scroll-left ])::after { --nc-table-shadow-left : 0 0; }
	&:not([data-nc-scroll-right])::after { --nc-table-shadow-right: 0 0; }
}

@mixin sticky-header-table {
	display: table;
	margin : 0;
	width  : var(--nc-width);

	overflow-x     : hidden;
	pointer-events : none;
	border-collapse: collapse;
}

@mixin sticky-scroll-bar {
	position       : fixed;
	inset-block-end: 0;
	z-index        : 99;
	margin         : 0;
	overflow-x     : auto;

	height: calc(1rem + 2px);  // for Safari
	border: 1px solid transparent;

	display: none;

	> div { height: 1px; }  // Spacer
}


// -----------------------------------------------------------------------------


@mixin sticky-header-container-print {
	display: none !important;
}

@mixin sticky-scroll-bar-print {
	display: none !important;
}
