/**
 * Masonry
 *
 * @author Takuto Yanagida
 * @version 2023-01-05
 */

%masonry {
	align-items: start;
	> * {
		position: var(--nc-container-masonry-position, static);
		width   : var(--nc-container-masonry-width, unset);
	}
}

@mixin masonry-n {
	@extend %masonry;
}
