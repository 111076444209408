/**
 * Print
 *
 * @author Takuto Yanagida
 * @version 2022-05-21
 */

@mixin property-print {
	--nc-print-font-size: 9pt;
}

@mixin print {
	font-size: var(--nc-print-font-size);
	* {
		-webkit-print-color-adjust: exact;
		print-color-adjust        : exact;
	}
}
