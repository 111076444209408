/**
 * Neat Width
 *
 * @author Takuto Yanagida
 * @version 2023-02-20
 */

/*
--nc-table-shadow-right: inset -2rem 0 1rem -1rem #0000001a;
--nc-table-shadow-left : inset  2rem 0 1rem -1rem #0000001a;
*/

@mixin neat($do-reset: true) {
	display  : block;
	width    : fit-content;
	max-width: 100%;
	overflow : auto;

	overscroll-behavior-x: contain;

	@if $do-reset {
		border-collapse: collapse;  // For reset
	}
}

@mixin full {
	width: 100%;

	border-collapse: collapse;
}


// -----------------------------------------------------------------------------


@mixin scroll {
	caption {
		display     : block;
		position    : sticky;
		inset-inline: 0;
	}

	&::after {
		content : '';
		display : flex;
		position: sticky;
		inset   : auto 0 0 0;

		pointer-events: none;

		height    : var(--nc-height);
		margin-top: calc(-1 * var(--nc-height));

		box-shadow:
			var(--nc-table-shadow-right, inset -2rem 0 1rem -1rem #0000001a),
			var(--nc-table-shadow-left,  inset  2rem 0 1rem -1rem #0000001a);
	}
}

@mixin scroll-right {
	--nc-table-shadow-left: 0 0;
}

@mixin scroll-left {
	--nc-table-shadow-right: 0 0;
}


// -----------------------------------------------------------------------------


@mixin neat-print {
	display: table;

	td, th {
		min-width  : initial !important;
		white-space: normal  !important;
	}
}

@mixin scroll-print {
	box-shadow: none !important;

	caption {
		display: table-caption;
	}
}
