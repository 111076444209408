/**
 * Main
 *
 * @author Takuto Yanagida
 * @version 2023-10-10
 */

/*
--nc-table-rule-color: #ddd;
--nc-table-rule-width: 2px;
--nc-table-rule-style: solid;

--nc-table-cell-padding: 0.25rem 0.5rem;
--nc-table-cell-rule   : transparent 0 solid;

--nc-table-cell-rule-color: #ddd;
	--nc-table-cell-rule-color-block
	--nc-table-cell-rule-color-inline
--nc-table-cell-rule-width: 1px;
	--nc-table-cell-rule-width-block
	--nc-table-cell-rule-width-inline
--nc-table-cell-rule-style: solid;
	--nc-table-cell-rule-style-block
	--nc-table-cell-rule-style-inline

--nc-table-cell-color      : unset;
--nc-table-cell-background : unset;
--nc-table-cell-font-weight: unset;
--nc-table-cell-text-align : unset;

--nc-table-header-color      : unset;
--nc-table-header-background : #ddd;
--nc-table-header-font-weight: bold;
--nc-table-header-text-align : center;

--nc-table-caption-font-size : 87.25%;
--nc-table-caption-text-align: center;
--nc-table-caption-max-width : 90%;
--nc-table-caption-margin    : 0.75rem;
*/

@mixin appearance {
	:is(tbody, tfoot) {
		border-block-end:
			var(--nc-table-rule-color, #ddd)
			var(--nc-table-rule-width, 2px)
			var(--nc-table-rule-style, solid);
	}

	:is(thead, tbody:first-child, caption + tbody, colgroup + tbody) {
		border-block-start:
			var(--nc-table-rule-color, #ddd)
			var(--nc-table-rule-width, 2px)
			var(--nc-table-rule-style, solid);

		> tr:first-child :is(th, td) {
			border-block-start: none;
		}
	}

	@include _appearance-cell-common;

	td {
		color      : var(--nc-table-cell-color, unset);
		background : var(--nc-table-cell-background, unset);
		font-weight: var(--nc-table-cell-font-weight, unset);
		text-align : var(--nc-table-cell-text-align, unset);

		vertical-align: top;

		p:not([class]) {
			text-align: unset;
		}
	}

	caption {
		font-size : var(--nc-table-caption-font-size, 87.25%);
		text-align: var(--nc-table-caption-text-align, center);

		> span {
			display   : inline-block;
			max-width : var(--nc-table-caption-max-width, 90%);
			text-align: start;
		}

		// Margin
		padding-block-end: var(--nc-table-caption-margin, 0.75rem);  // Must use 'padding-block-end'
	}
}

@mixin appearance-header {
	:is(thead) {
		border-block-start:
			var(--nc-table-rule-color, #ddd)
			var(--nc-table-rule-width, 2px)
			var(--nc-table-rule-style, solid);

		> tr:first-child :is(th, td) {
			border-block-start: none;
		}
	}

	@include _appearance-cell-common;
}

@mixin _appearance-cell-common {
	font-feature-settings: 'pkna' 1;

	:is(th, td) {
		padding: var(--nc-table-cell-padding, 0.25rem 0.5rem);
		border : var(--nc-table-cell-rule, transparent 0 solid);

		border-block-start:
			var(--nc-table-cell-rule-color-block, var(--nc-table-cell-rule-color, #ddd))
			var(--nc-table-cell-rule-width-block, var(--nc-table-cell-rule-width, 1px))
			var(--nc-table-cell-rule-style-block, var(--nc-table-cell-rule-style, solid));

		&:where(:not(:first-child)) {
			border-inline-start:
				var(--nc-table-cell-rule-color-inline, var(--nc-table-cell-rule-color, #ddd))
				var(--nc-table-cell-rule-width-inline, var(--nc-table-cell-rule-width, 1px))
				var(--nc-table-cell-rule-style-inline, var(--nc-table-cell-rule-style, solid));
		}
		&:where(:not(:last-child)) {
			border-inline-end:
				var(--nc-table-cell-rule-color-inline, var(--nc-table-cell-rule-color, #ddd))
				var(--nc-table-cell-rule-width-inline, var(--nc-table-cell-rule-width, 1px))
				var(--nc-table-cell-rule-style-inline, var(--nc-table-cell-rule-style, solid));
		}
	}

	:is(thead td, th) {
		color      : var(--nc-table-header-color, unset);
		background : var(--nc-table-header-background, #ddd);
		font-weight: var(--nc-table-header-font-weight, bold);
		text-align : var(--nc-table-header-text-align, center);
	}
}


// -----------------------------------------------------------------------------


@mixin appearance-stripe {
	tr:nth-child(odd) > td {
		background: var(--nc-table-background-second, #eee);
	}
}
