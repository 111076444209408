/**
 * Wrapper of Input Number
 *
 * @author Takuto Yanagida
 * @version 2021-12-26
 */

@mixin wrapper-type-number {
	display: inline-flex;

	// For Chrome & Safari
	@supports (not (-moz-appearance: none)) {
		position: relative;
		&::after {
			content  : '';
			position : absolute;
			top      : 50%;
			right    : 0.25em;
			transform: translateY(-50%);

			width : 1em;
			height: 1em;

			box-shadow: inset 0 0 0 2em var(--nc-input-mark-color);  // For print
			clip-path : polygon(50% 0%, 25% 35%, 50% 35%,
			50% 65%, 25% 65%, 50% 100%, 75% 65%, 50% 65%,
			50% 35%, 75% 35%);

			pointer-events: none;
		}

		input[type='number' i] {
			padding-inline-end: 1.5em;

			position: relative;
			&::-webkit-inner-spin-button {
				position        : absolute;
				inset-inline-end: 0;
				transform-origin: right center;
				opacity         : 0;
			}
		}
	}
	// For Chrome
	@supports (-webkit-appearance: none) and (not (-moz-appearance: none)) and (not (font: -apple-system-body)) {
		input[type='number']::-webkit-inner-spin-button {
			inset-block: 0;
			transform  : scale(1.65);
		}
	}
	// For Safari
	@supports (not (-moz-appearance: none)) {
		input[type='number']::-webkit-inner-spin-button {
			inset-block-start: 50%;
			transform        : translateY(-50%) scale(1.25);
		}
	}
}
