/**
 * Fieldset
 *
 * @author Takuto Yanagida
 * @version 2021-12-27
 */

@mixin fieldset {
	padding: var(--nc-fieldset-padding);

	border       : var(--nc-fieldset-border);
	border-radius: var(--nc-form-border-radius);
}
