/**
 * Wrapper of Select
 *
 * @author Takuto Yanagida
 * @version 2021-12-26
 */

@mixin wrapper-select {
	display: inline-flex;

	position: relative;
	&::after {
		content  : '';
		position : absolute;
		top      : 50%;
		right    : 0.25em;
		transform: translateY(-50%);

		width : 1em;
		height: 1em;

		box-shadow: inset 0 0 0 2em var(--nc-input-mark-color);  // For print
		clip-path : polygon(20% 25%, 50% 75%, 80% 25%);

		pointer-events: none;
	}

	select {
		padding-inline-end: 1.5em;
	}
}
