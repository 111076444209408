/**
 * Default Content Style
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/_wrapper' as *;

:root {
	@include property($tab-style: 'tab');
}

.user-content {
	@include align;
	@include column;
	@include content($do-set-heading: false);
	@include list;
	@include tab($tab-style: 'tab');
	@include table;
}

@include font;
@include form;
@include ja;
@include link;
@include viewer;
